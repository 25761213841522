export default {
    en: {
        fruits: [
            "Apple",
            "Apricot",
            "Avocado",
            "Banana",
            "Barberry",
            "Black currant",
            "Blackberry",
            "Blueberry",
            "Cape gooseberry",
            "Carambola",
            "Cherimoya",
            "Cherry",
            "Cinnamon Apple",
            "Clementine",
            "Cocoa pod",
            "Coconut",
            "Cowardly",
            "Cranberries",
            "Date",
            "Durian",
            "Elderberry",
            "Galo",
            "Gooseberry",
            "Grape",
            "Grapefruit",
            "Guava",
            "Honeydew melon",
            "Jackfruit",
            "Japanese medlar",
            "Kaki",
            "Kassia",
            "Kiwi",
            "Kumquats",
            "Lemon",
            "Lime",
            "Loquat",
            "Lychee",
            "Mango",
            "Mangosteen",
            "Manna",
            "Melon",
            "Mirabelle",
            "Nectarine",
            "Olive",
            "Orange",
            "Pear",
            "Pineapple",
            "Pomegranate",
            "Prickly Pear",
            "Tamarillo",
            "Tamarind",
            "Tangerine",
            "Quince",
            "Raisins",
            "Rambutan",
            "Raspberry",
            "Red Currant",
            "Sapotille",
            "Schuppenannone",
            "Sea buckthorn",
            "Sharon fruit",
            "Sloes",
            "Star fruit",
            "Strawberry",
            "Papaya",
            "Passion Fruit",
            "Peach",
            "Physallis",
            "Plum",
            "Watermelon",
        ]
    },
    de: {
        fruits: [
            "Ananas",
            "Apfel",
            "Apfelsine",
            "Aprikose",
            "Avocado",
            "Banane",
            "Berberitze",
            "Birne",
            "Blaubeere",
            "Brombeere",
            "Cherimoya",
            "Cranberries",
            "Clementine",
            "Dattel",
            "Durian",
            "Erdbeere",
            "Feige",
            "Galo",
            "Granatapfel",
            "Grapefruit",
            "Guave",
            "Heidelbeere",
            "Himbeere",
            "Holunderbeere",
            "Honigmelone",
            "Jackfrucht",
            "Japanische Mispel",
            "Rote Johanisbeere",
            "Schwarze Johanisbeere",
            "Kakaoschote",
            "Kaki",
            "Kaktusfeige",
            "Kapstachelbeere",
            "Karambole",
            "Kassia",
            "Kirsche",
            "Kiwi",
            "Kokosnuss",
            "Kumquats",
            "Litschi",
            "Limette",
            "Loquat",
            "Mandarine",
            "Mango",
            "Mangostane",
            "Manna",
            "Melone",
            "Mirabelle",
            "Nektarine",
            "Orange",
            "Olive",
            "Pampelmuse",
            "Papaya",
            "Passionsfrucht",
            "Pfirsich",
            "Pflaume",
            "Physallis",
            "Preiselbeeren",
            "Quitte",
            "Rambutan",
            "Rosinen",
            "Sanddorn",
            "Sapotille",
            "Schlehen",
            "Schuppenannone",
            "Sharonfrucht",
            "Stachelbeere",
            "Sternfrucht",
            "Tamarillo",
            "Tamarinde",
            "Traube",
            "Wassermelone",
            "Zimtapfel",
            "Zitrone",
            "Zwetschge"
        ]
    }
}