export default {
    en: {
        flowers: [
            "Adonsi roses",
            "African Lilies",
            "Allium",
            "Amaryllis",
            "Amur Adonis",
            "Anemone",
            "Asters",
            "Astrantia",
            "Astilbe",
            "Astzer",
            "Autumn Crocus",
            "Autumn Timeless",
            "Beard thread",
            "Bellflower",
            "Bergenia",
            "Black-eyed Susanne",
            "Blue pillow",
            "Bluebell",
            "Bluestars",
            "Broom",
            "Bunny Bells",
            "Calla",
            "Candytuft",
            "Cape basket",
            "Carnations",
            "Catchfly",
            "Celosia",
            "Checkerboard flower",
            "Christmas rose",
            "Chrysanthemums",
            "Cockade flower",
            "Cockscomb",
            "Columbine",
            "Cone flower",
            "Corkus",
            "Corn poppy",
            "Cornflower",
            "Cranesbill",
            "Craspedia",
            "Cyclamen",
            "Daffodil",
            "Dahlia",
            "Daisies",
            "Delphinium",
            "Dog tooth lily",
            "Douglas Marsh Flower",
            "Dyer's chamomile",
            "Early bloomers",
            "Elf mirror",
            "Elven flower",
            "Euphorbia",
            "Evening Primrose",
            "Fire Sage",
            "Floor Primrose",
            "Forget-Me-Not",
            "Freesia",
            "Funkie",
            "Gentian",
            "Geraniums",
            "Gerbera",
            "Germini",
            "Girl's eye",
            "Gladioli",
            "Gloriosa",
            "Gold Crocus",
            "Gold Poppy",
            "Golden strawberry",
            "Goldenrod",
            "Grape hyacinth",
            "Guernsey Lily",
            "Gypsophila",
            "Heart flower",
            "Heart-leaved foam blossom",
            "Heather carnation",
            "Hellebore",
            "Herbst-Eisenhut",
            "Herbst-Fettblatt",
            "Hollyhock",
            "Hook lily",
            "Horn Violet",
            "Hussar's Head",
            "Hyacinths",
            "Hydrangeas",
            "Ice flower",
            "Imperial Crown",
            "Inca Lily",
            "Iris",
            "Jewelry basket",
            "Juggler Flower",
            "Kalanchoe",
            "Knapweed",
            "Kornrade",
            "Larksporn",
            "Lavender",
            "Leather balm",
            "Lederblümchen",
            "Lenten Rose",
            "Levkoje",
            "Lilies",
            "Lily of the valley",
            "Lisianthus",
            "Loyal to men",
            "Lupine",
            "Magerite",
            "Magic bells",
            "Magnificent candle",
            "Maiden in the Green",
            "Mallow",
            "Marigold",
            "Märzenbecher",
            "Midday Gold",
            "Milky Star",
            "Monkshood",
            "Montbretie",
            "Morning glory",
            "Mustard",
            "Nasturtium",
            "Nerine",
            "Orchids",
            "Passion Flower",
            "Peonies",
            "Perennial Sunflower",
            "Petunias",
            "Phlox",
            "Pillow Aster",
            "Pin cushion",
            "Prairie Lily",
            "Pre-Summer Phlox",
            "Protea",
            "Purple bells",
            "Purslane",
            "Ranunculus",
            "Real Marshmallow",
            "Roses",
            "Rough Leaf Aster",
            "Safflower",
            "Saffron Crocus",
            "Santini",
            "Scabious",
            "Scented Nettle",
            "Sea lavender",
            "Sedum plant",
            "Sheep gift",
            "Snapdragon",
            "Snowball",
            "Snowdrop",
            "Spanish rabbit bell",
            "Straw flower",
            "Strelitzia",
            "Summer Azalea",
            "Sun Bride",
            "Sun Eye",
            "Sun hat",
            "Sunflower",
            "Thistles",
            "Thlaspi",
            "Torch lilies",
            "Trumpet tongue",
            "Tulip",
            "Verbena",
            "Violet",
            "Wax flower",
            "Winter Heath",
            "Winter Jasmine",
            "Winterling",
            "Witch Hazel",
            "Zinnia",
        ]
    },
    de: {
        flowers: [
            "Allium",
            "Amaryllis",
            "Anemone",
            "Astern",
            "Astrantia",
            "Bartnelke",
            "Calla",
            "Celosia",
            "Christrosen",
            "Chrysanthemen",
            "Craspedia",
            "Dahlien",
            "Disteln",
            "Enzian",
            "Euphorbia",
            "Fackellilien",
            "Färberdistel",
            "Fetthenne",
            "Freesien",
            "Frühblüher",
            "Gerbera",
            "Germini",
            "Ginster",
            "Gladiolen",
            "Gloriosa",
            "Goldrute",
            "Guernseylilie",
            "Hortensien",
            "Hyazinthen",
            "Inkalilie",
            "Iris",
            "Jungfer im Grünen",
            "Kalanchoe",
            "Lavendel",
            "Lederbalsam",
            "Lilien",
            "Lisianthus",
            "Löwenmäulchen",
            "Margeriten",
            "Milchstern",
            "Montbretien",
            "Nadelkissen",
            "Narzissen",
            "Nelken",
            "Orchideen",
            "Pfingstrosen",
            "Phlox",
            "Protea",
            "Ranunkeln",
            "Rittersporn",
            "Rosen",
            "Santini",
            "Schafgabe",
            "Schleierkraut",
            "Schmucklilien",
            "Schneeball",
            "Sonnenblumen",
            "Sonnenbraut",
            "Strandflieder",
            "Strelitzien",
            "Thlaspi",
            "Traubenhyazinthe",
            "Tulpen",
            "Wachsflower",
            "Zauberglöckchen",
            //weitere
            //sonnig
            "Akelei",
            "Blaukissen",
            "Vergissmeinnicht",
            "Pfingstrose",
            "Veilchen",
            "Herzblume",
            //schattig
            "Bergenie",
            "Elfenblume",
            "Golderdbeere",
            "Herzblättrige Schaumblüte",
            "Maiglöckchen",
            //sonnig
            "Anemone",
            "Blausternchen",
            "Enzian",
            "Hyazinthe",
            "Kaiserkrone",
            "Kegelblume",
            "Korkus",
            "Märzenbecher",
            "Milchstern",
            "Narzisse",
            "Lenzrose",
            "Prärielilie",
            "Schleifenblume",
            "Schwertlilie",
            "Sternhyazinthe",
            "Tulpe",
            "Traubenhyazinthe",
            //halbschattig
            "Hasenglöckchen",
            "Lerchensporn",
            "HUndszahnlilie",
            "Lederblümchen",
            "Schachbrettblume",
            "Schneeglöckchen",
            "Spanisches Hasenglöckchen",
            "Winterling",
            //Sommerblumen mehrjährig
            //sonnig
            "Duftnessel",
            "Echter Eibisch",
            "Färberkamille",
            "Flockenblume",
            "Mädchenauge",
            "Heide-Nelke",
            "Horn-Veilchen",
            "Glockenblume",
            "Kokardenblume",
            "Lupine",
            "Prachtkerze",
            "Rittersporn",
            "Rose",
            "Schmucklilie",
            "Magerite",
            "Phlox",
            "Sonnenauge",
            "Sonnenbraut",
            "Sonnenhut",
            //schattig
            "Astilbe",
            "Eisenhut",
            "Funkie",
            "Gauklerblume",
            "Purpurglöckchen",
            "Storchschnabel",
            "Stockrose",
            "Storchschnabel",
            "Vorsommer-Phlox",
            //Sommerblühende Zwiebelblumen
            //halbschattig
            "Etagen-Primel",
            "Fackellilie",
            "Hakenlilie",
            "Lilie",
            "Montbretie",
            //Sommerblumen einjährig
            "Adonsiröschen",
            "Astzer",
            "Bartfaden",
            "Elfenspiegel",
            "Eisenkraut",
            "Feuersalbei",
            "Löwenmaul",
            "Geranien",
            "Hahnenkamm",
            "Husarenköpfchen",
            "Jungfer im Grünen",
            "Goldmohn",
            "Kapkörbchen",
            "Kapuzinerkresse",
            "Kornrade",
            "Klatschmohn",
            "Kornblume",
            "Mädchenauge",
            "Malve",
            "Männertreu",
            "Mittagsblume",
            "Mittagsgold",
            "Passionsblume",
            "Petunien",
            "Portulakröschen",
            "Prunkwinde",
            "Ringelblume",
            "Schmuckkörbchen",
            "Schwarzäugige Susanne",
            "Trompetenzunge",
            "Sommer-Azalee",
            "Levkoje",
            "Sonnenblume",
            "Douglas-Sumpfblume",
            "Strandflieder",
            "Strohblume",
            "Studentenblume",
            "Witwenblume",
            "Zinnie",
            //Herbstblüher
            //mehrjährig
            "Alpenveilchen",
            "Kissen-Aster",
            "Leimkraut",
            "Herbst-Eisenhut",
            "Herbst-Fettblatt",
            "Rauhblatt-Aster",
            "Stauden-Sonnenblume",
            //Herbstblühende Zwiebelblumen
            "Dahlie",
            "Goldkrokus",
            "Herbst-Krokus",
            "Herbst-Zeitlose",
            "Nachtkerze",
            "Nerine",
            "Safran-Krokus",
            //Winterblumen
            //Mehrjährig
            "Amur-Adonisröschen",
            "Christrose",
            "Nieswurz",
            "Winterheide",
            "Winterjasmin",
            "Zaubernuss",
        ]
    }
}