export default {
    en: {
        /*"Aloe Vera",
            "Anise",
            "Aralia",
            "Arnika",
            "Astralagus",
            "Basil",
            "Bay Leaves",
            "Bayberry",
            "Bee balm",
            "Bergamot",
            "Borage",
            "Burnet",
            "Calamint",
            "Calendula",
            "Caraway",
            "Catnip",
            "Cayenne",
            "Celery",
            "Chamomile",
            "Chervil",
            "Chives",
            "Cicely",
            "Cilantro",
            "Comfrey",
            "Coriander",
            "Costmary",
            "Cotula",
            "Cuban Oregano",
            "Culantro",
            "Dill",
            "Dittany of Crete",
            "Epazote",
            "Fennel",
            "Feverfew",
            "Foxglove",
            "Garlic",
            "Geraniums",
            "Germander",
            "Ginger",
            "Gingko Biloba",
            "Gingseng",
            "Golden Rod",
            "Hibiscus",
            "Horehound",
            "Horseradish",
            "Hyssop",
            "Wild Pansy",
            "Laurel",
            "Lavender",
            "Lemon Balm",
            "Lemon verbena",
            "Lemongrass",
            "Licorice Root",
            "Lovage",
            "Lungwort",
            "Majoram",
            "Milk Thistle",
            "Mint",
            "Mugwort",
            "Mullein",
            "Myrtle Plant",
            "Nasturtium",
            "Nettle",
            "Oregano",
            "Parsley",
            "Patchouli",
            "Pennyroyal",
            "Peppermint",
            "Rosemary",
            "Rue",
            "St. John's Wort",
            "Saffron",
            "Sage",
            "Salvia",
            "Santolina",
            "Savory",
            "Scullcap",
            "Sorrel",
            "Southernwood",
            "Stevia",
            "Sweet bay",
            "Sweet cicely",
            "Sweet Grass",
            "Sweet woodruff",
            "Tansy",
            "Tarragon",
            "Thyme",
            "Holy Basil",
            "Tumeric",
            "Valerian",
            "Vitex",
            "Witch Hazel",
            "Winter Savory",
            "Woodruff",
            "Wormwood",
            "Yarrow",
            "Zedoary"
        */
        herbs: [
            "Anise",
            "Arnica",
            "Basil",
            "Borage",
            "Black cumin",
            "Bloodroot",
            "Celandine",
            "Chamomile",
            "Chervil",
            "Chives",
            "Coriander",
            "Dill",
            "Fennel",
            "Fenugreek",
            "Johannis herbs",
            "Kümel",
            "Lavender",
            "Lein",
            "Lemon balm",
            "Licorice",
            "Lovage",
            "Marjoram",
            "Mugwort",
            "Mushroom herb",
            "Nettle",
            "Olive herb",
            "Oregano",
            "Parsley",
            "Peppermint",
            "Rosmain",
            "Saffron",
            "Sage",
            "Savory",
            "Sorrel",
            "Stevia",
            "Tarragon",
            "Thyme",
            "Valerian",
            "Verbena",
            "Vermouth",
            "Wild garlic",
            "Woodruff",
            "Yarrow",
            "Yosp",
        ]
    },
    de: {
        /*
            "Aloe Vera",
            "Aralia",
            "Astralagus",
            "Lorbeerblätter",
            "Myrica",
            "Monarda",
            "Bergamotte",
            "Borretsch",
            "Kleiner Wiesenknopf",
            "Bergminzen",
            "Ringelblume",
            "Kümmel",
            "Katzenminze",
            "Cayenne",
            "Kamille",
            "Kerbel",
            "Schnittlauch",
            "Süßdolde",
            "Koriander",
            "Beinwell",
            "Frauenminze",
            "Laugenblumen",
            "Mexikanischer Oregano",
            "Langer Koriander",
            "Dill",
            "Diptam-Dost",
            "Mexikanischer Drüsengänsefuß",
            "Fenchel",
            "Mutterkraut",
            "Fingerhut",
            "Knoblauch",
            "Storchschnäbel",
            "Germander",
            "Ingwer",
            "Gingseng",
            "Goldruten",
            "Hibiskus",
            "Andorn",
            "Meerrettich",
            "Ysop",
            "Wildes Stiefmütterchen",
            "Gewürzlorbeer",
            "Lavendel",
            "Zitronenmeslisse",
            "Zitronenstrauch",
            "Zitronengras",
            "Liebstöckel",
            "Lungenkraut",
            "Majoran",
            "Mariendistel",
            "Minze",
            "Beifuß",
            "Königskerze",
            "Myrten",
            "Brunnenkresse",
            "Kapuzinerkresse",
            "Brennnessel",
            "Oregano",
            "Patschuli",
            "Polei Minze",
            "Pfefferminze",
            "Rosmarin",
            "Weinraute",
            "Johanniskraut",
            "Salbei",
            "Azteken-Salbei",
            "Heiligenkraut",
            "Bohnenkraut",
            "Sellerie",
            "Helmkraut",
            "Wiesen-Sauerampfer",
            "Eberraute",
            "Stevia",
            "Echter Lorbeer",
            "Süßdolde",
            "Mariengras",
            "Waldmeister",
            "Rainfarn",
            "Estragon",
            "Thymian",
            "Inisches Basilikum",
            "Kurkuma",
            "Baldrian",
            "Mönchspfeffer",
            "Zaubernuss",
            "Winter-Bohnenkraut",
            "Wermut",
            "Schafgabe",
            "Zitwerwurzel"
            */
        herbs: [
            "Anis",
            "Arnika",
            "Baldrian",
            "Bärlauch",
            "Bärwurz",
            "Basilikum",
            "Beifuß",
            "Blutwurz",
            "Bockshornklee",
            "Bohnenkraut",
            "Borretsch",
            "Brennnessel",
            "Dill",
            "Eisenkraut",
            "Estragon",
            "Fenchel",
            "Johanniskraut",
            "Kerbel",
            "Kamille",
            "Koriander",
            "Kümel",
            "Lavendel",
            "Lein",
            "Liebstöckel",
            "Majoran",
            "Olivenkraut",
            "Oregano",
            "Petersilie",
            "Pfefferminze",
            "Pilzkraut",
            "Rosmain",
            "Salbei",
            "Safran",
            "Sauerampfer",
            "Schafgarbe",
            "Schnittlauch",
            "Schöllkraut",
            "Schwarzkümmel",
            "Stevia",
            "Süßholz",
            "Thymian",
            "Waldmeister",
            "Wermut",
            "Yosp",
            "Zitronenmelisse",
        ]
    }
}