export default {
    en: {
        /*
            "Breadfruit Tree",
            "Durian Tree",
            "Calabash Tree",
            "Jackfruit Tree",
            "Olive Tree",
            "Banyan Tree",
            "Black Ash Tree",
            "White Ash Tree",
            "Neem Tree",
            "Bigtooth Aspen Tree",
            "Quaking Aspen Tree",
            "Mahogany Tree",
            "Basswood Tree",
            "American Beech Tree",
            "Tulip Tree",
            "Black Birch Tree",
            "Gray Birch Tree",
            "Paper Birch Tree",
            "Yellow Birch Tree",
            "Butternut Tree",
            "Black Cherry Tree",
            "Pin Cherry Tree",
            "American Chestnut Tree",
            "Eastern Cottonwood Tree",
            "Cucumber Tree",
            "American Elm Tree",
            "English Elm Tree",
            "Slippery Elm Tree",
            "Hawthorn Tree",
            "Black Locust Tree",
            "Honey Locust Tree",
            "Bitternut Hickory Tree",
            "Pignut Hickory Tree",
            "Shagbark Hickory Tree",
            "American Hophornbeam Tree",
            "American Hornbeam Tree",
            "Tamarack Tree",
            "The European Larch Tree",
            "Red Maple Tree",
            "Silver Maple Tree",
            "Sugar Maple Tree",
            "Black Oak Tree",
            "Chestnut Oak Tree",
            "Northern Red Oak Tree",
            "Scarlet Oak Tree",
            "White Oak Tree",
            "Eastern White Pine Tree",
            "Sassafras Tree",
            "Serviceberry Tree",
            "Black Walnut Tree",
            "Black Willow Tree",
            "Flowering Dogwood Tree",
            "Australian Mountain Ash Tree",
            "Balsam Fir Tree",
            "Eastern Hemlock Tree",
            "Pitch Pine Tree",
            "Ginkgo",
            "Red Pine Tree",
            "Scots Pine Tree",
            "Eastern Red Cedar Tree",
            "Red Spruce Tree",
            "White Spruce Tree",           
            "Sycamore Tree"
        */
       /*
       //häufigste Bäume Deutschland
            "Ahorn",
            "Apfelbaum",
            "Birke",
            "Birnbaum",
            "Buche",
            "Eberesche",
            "Eibe",
            "Eiche",
            "Erle",
            "Esche",
            "Fichte",
            "Hainbuche",
            "Hasel",
            "Kastanie",
            "Kiefer",
            "Kirschbaum",
            "Lärche",
            "Linde",
            "Pappel",
            "Scheinakazie",
            "Stechpalme",
            "Schwarzdorn",
            "Tanne",
            "Wacholder",
            "Weide",
            "Weißdorn",
            "Ulme",
            //weitere Baumliste
            //Laubbäume
            "Japanischer Ahorn",
            "Fächerahorn",
            "Amberbaum",
            "Blauglockenbaum",
            "Elsbeere",
            "Gingko",
            "Mehlbeere",
            "Platane",
            "Akazine",
            "Roßkastanie",
            "Speierling",
            //Nadelbäume
            "Douglasie",
            "Goldlärche",
            "Hemlocktanne",
            "Lebensbaum",
            "Scheinzypresse",
            "Sicheltanne",
            "Nobilis Tanne",
            "Korea Tanne",
            "Nordmann Tanne",
            "Kolorado Tanne",
            "Küsten Tanne",
            "Wacholder",
            "Zeder",
            "Zypresse",
            //Nussbaäume
            "Haselnussbaum", 
            "Maronenbaum", 
            "Mandelbaum", 
            "Schwarznussbaum", 
            "Walnussbaum",
            //Obstbäume 
            "Birnbaum",
            "Pflaumenbaum", 
            "Pfirsichbaum",
            //weitere
            "Buchsbaum",
            "Espe",
            "Stechpalme",
            "Eucalyptus",
            "Thuja",
            "Magnolien",
            "Baumhasel",
            "Kirschlorbeer",
        */
        trees: [
            "Acacia",
            "Alder",
            "Almond Tree",
            "Apple tree",
            "Ash",
            "Aspen",
            "Beech",
            "Birch",
            "Black walnut tree",
            "Blackthorn",
            "Bluebell tree",
            "Boxwood",
            "Buckeye",
            "Cedar",
            "Chestnut",
            "Cherry laurel",
            "Cherry tree",
            "Chestnut tree",
            "Coastal Fir",
            "Colorado Fir",
            "Crescent Fir",
            "Cypress",
            "Douglas fir",
            "Elm",
            "Eucalyptus",
            "Fake Acacia",
            "False cypress",
            "Fir",
            "Gingko",
            "Golden larch",
            "Hawthorn",
            "Hazel",
            "Hazelnut tree",
            "Hemlock",
            "Holly",
            "Hornbeam",
            "Japanese maple",
            "Jaw",
            "Juniper",
            "Korea Fir",
            "Larch",
            "Linden",
            "Magnolias",
            "Maple",
            "Nobilis Fir",
            "Nordmann Fir",
            "Oak",
            "Pasture",
            "Peach tree",
            "Pear tree",
            "Plane",
            "Plum tree",
            "Poplar",
            "Rowan",
            "Service tree",
            "Speierling",
            "Spruce",
            "Sweet Gum",
            "Thuja",
            "Tree hazel",
            "Tree of Life",
            "Walnut tree",
            "Whitebeam",
            "Yew",
        ]
    },
    de: {
        trees: [
            "Ahorn",
            "Akazie",
            "Amberbaum",
            "Apfelbaum",
            "Baumhasel",
            "Birke",
            "Birnbaum",
            "Blauglockenbaum",
            "Buche",
            "Buchsbaum",
            "Douglasie",
            "Eberesche",
            "Eibe",
            "Eiche",
            "Elsbeere",
            "Erle",
            "Esche",
            "Espe",
            "Eucalyptus",
            "Fächerahorn",
            "Fichte",
            "Gingko",
            "Goldlärche",
            "Hainbuche",
            "Hasel",
            "Haselnussbaum", 
            "Hemlocktanne",
            "Kastanie",
            "Kiefer",
            "Kirschbaum",
            "Kirschlorbeer",
            "Kolorado Tanne",
            "Korea Tanne",
            "Küsten Tanne",
            "Magnolien",
            "Mandelbaum",
            "Maronenbaum", 
            "Mehlbeere",
            "Nobilis Tanne",
            "Nordmann Tanne",
            "Lärche",
            "Lebensbaum",
            "Linde",
            "Ulme",
            "Japanischer Ahorn",
            "Tanne",
            "Thuja",
            "Pappel",
            "Pfirsichbaum",
            "Pflaumenbaum", 
            "Platane",
            "Roßkastanie",
            "Scheinakazie",
            "Scheinzypresse",
            "Schwarzdorn",
            "Schwarznussbaum",
            "Sicheltanne",
            "Speierling",
            "Stechpalme",
            "Wacholder",
            "Walnussbaum",
            "Weide",
            "Weißdorn",
            "Zeder",
            "Zypresse",
        ]
    }
}