export default {
    en: {
        /*"Chayote",
            "Amaranth Leaves",
            "Arrowroot",
            "Artichoke",
            "Arugula",
            "Asparagus",
            "Bamboo Shoots",
            "Beans, Green",
            "Beets",
            "Belgian Endive",
            "Bitter Melon*",
            "Bok Choy",
            "Broadbeans (Fava Beans)",
            "Broccoli","Broccoli Rabe",
            "Brussel Sprouts",
            "Cabbage, Green",
            "Cabbage, Red",
            "Carrot",
            "Cassava (Yuca Root)",
            "Cauliflower",
            "Celeriac (Celery Root)",
            "Celery",
            "Chayote*",
            "Chicory (Curly Endive)",
            "Collards",
            "Corn",
            "Crookneck",
            "Cucumber",
            "Daikon",
            "Dandelion Greens",
            "Edamame, Soybeans",
            "Eggplant",
            "Fennel",
            "Fiddleheads",
            "Ginger Root",
            "Horseradish",
            "Jicama",
            "Kale",
            "Kohlrabi",
            "Leeks",
            "Lettuce, Iceberg",
            "Lettuce, Leaf",
            "Lettuce, Romaine",
            "Mushrooms",
            "Mustard Greens",
            "Okra",
            "Onion (Red)",
            "Onions",
            "Parsnip",
            "Peas, Green",
            "Pepper, Green",
            "Red pepper",
            "Pepper, Sweet Red",
            "Potato, Red",
            "Potato, White",
            "potatoes_small",
            "Potato, Yellow",
            "Pumpkin",
            "Radicchio",
            "Radishes",
            "Rutabaga",
            "Salsify (Oysterplant)",
            "Shallots",
            "Snow Peas",
            "Sorrel (Dock)",
            "Spaghetti Squash",
            "Spinach",
            "Squash, Butternut",
            "Sugar Snap Peas",
            "Sweet Potato",
            "Swiss Chard",
            "Tomatillo",
            "Tomato",
            "Turnip",
            "Watercress",
            "Yam Root",           
            "Zucchini"
        */
        vegetables: [
            "Artichoke",
            "Arugula",
            "Asparagus",
            "Aubergine",
            "Autumn turnip",
            "Avocado",
            "Beans",
            "Beetroot",
            "Black Radish",
            "Borage",
            "Bottle gourd",
            "Broccoli",
            "Brussels sprouts",
            "Cabbage",
            "Carrots",
            "Cauliflower",
            "Celeriac",
            "Celery root",
            "Chicory",
            "Chili",
            "Chinese cabbage",
            "Cress",
            "Cucumber",
            "Dandelion",
            "Endive",
            "Fennel",
            "Field Garlic",
            "Garden dock",
            "Garlic",
            "Giersch",
            "Ginger",
            "Gundermann",
            "Hop",
            "Iceberg lettuce",
            "Jerusalem artichoke",
            "Kale",
            "Karpern",
            "Kohlrabi",
            "Lamb's lettuce",
            "Leek",
            "Lenses",
            "Lettuce",
            "Meadow clover",
            "Nasturtium",
            "Oak leaf salad",
            "Okra",
            "Onion",
            "Ornamental cabbage",
            "Palm kale",
            "Paprika",
            "Parsley root",
            "Parsnips",
            "Pearl onion",
            "Peas",
            "Pickled cucumber",
            "Plantain",
            "Pok Choi",
            "Potato",
            "Protulak",
            "Pumpkin",
            "Radish",
            "Red cabbage",
            "Rhubarb",
            "Romanesco",
            "Savoy",
            "Salad",
            "Shallot",
            "Silver onion",
            "Sponge Pumpkin",
            "Spinach",
            "Sprouts",
            "Sweet potato",
            "Swiss chard",
            "Tomato",
            "Turnip",
            "Watercress",
            "White cabbage",
            "Yacon",
            "Zucchini",
        ]
    },
    de: {
        vegetables: [
            "Ackerlauch",
            "Artischoke",
            "Aubergine",
            "Avocado",
            "Blumenkohl",
            "Bohnen",
            "Borretsch",
            "Brokkoli",
            "Brunnenkresse",
            "Chicoree",
            "Chili",
            "Chinakohl",
            "Eichblattsalat",
            "Eisbergsalat",
            "Endivie",
            "Erbsen",
            "Feldsalat",
            "Fenchel",
            "Flaschenkürbis",
            "Garten-Ampfer",
            "Gewürzgurke",
            "Giersch",
            "Grünkohl",
            "Gundermann",
            "Herbstrübe",
            "Hopfen",
            "Ingwer",
            "Kapuzinerkresse",
            "Karotten",
            "Karpern",
            "Kartoffel",
            "Knoblauch",
            "Knollensellerie",
            "Kohlrabi",
            "Kopfsalat",
            "Kresse",
            "Kürbis",
            "Lauch",
            "Linsen",
            "Löwenzahn",
            "Mangold",
            "Okra",
            "Palmkohl",
            "Paprika",
            "Pastinaken",
            "Perlzwiebel",
            "Petersilienwurzel",
            "Pok Choi",
            "Protulak",
            "Radieschen",
            "Rettich",
            "Rhabarber",
            "Romanesco",
            "Rosenkohl",
            "Rote Beete",
            "Rotkohl",
            "Rucola",
            "Salatgurke",
            "Schalotte",
            "Schnittsalat",
            "Schwammkürbis",
            "Schwarzer Rettich",
            "Silberzwiebel",
            "Spargel",
            "Spinat",
            "Spitzkohl",
            "Sprossen",
            "Stangensellerie",
            "Steckrübe",
            "Süßkartoffel",
            "Tomate",
            "Topinambur",
            "Wegerich",
            "Weißkohl",
            "Wiesenklee",
            "Wirsing",
            "Yacon",
            "Zierkohl",
            "Zucchini",
            "Zwiebel",
        ]
    }
}