export default {
    en: {
        /*
            "Adenium",
            "Adromischus",
            "Aeonium",
            "Agave",
            "Albuca",
            "Aloe",
            "Aloinopsis",
            "Alworthia",
            "Anacampseros",
            "Antegibbaeum",
            "Antimima",
            "Aptenia",
            "Argyroderma",
            "Astridia",
            "Astroloba",
            "Astroworthia",
            "Avonia",
            "Bergeranthus",
            "Bijlia",
            "Braunsia",
            "Callisia",
            "Carruanthus",
            "Cephalophyllum",
            "Ceropegia",
            "Cheiridopsis",
            "Conophytum",
            "Corpuscularia",
            "Cotyledon",
            "Crassula",
            "Cremnosedum",
            "Dinteranthus",
            "Dudleya",
            "Dyckia",
            "Ebracteola",
            "Echeveria",
            "Echidnopsis",
            "Euphorbia",
            "Faucaria",
            "Fenestaria",
            "Frithia",
            "Gasteraloe",
            "Gasterhaworthia",
            "Gasteria",
            "Gibbaeum",
            "Glottiphyllum",
            "Graptopetalum",
            "Graptosedum",
            "Graptoveria",
            "Haworthia",
            "Huernia",
            "Ihlenfeldtia",
            "Jensenobotrya",
            "Juttadinteria",
            "Kalanchoe",
            "Lapidaria",
            "Larryleachia",
            "Lenophyllum",
            "Lithops",
            "Mangave",
            "Monanthes",
            "Neohenricia",
            "Odontophorus",
            "Orbea",
            "Orostachys",
            "Oscularia",
            "Othonna",
            "Pachyphytum",
            "Pachyveria",
            "Peperomia",
            "Pleiospilos",
            "Portulaca",
            "Portulacaria",
            "Prenia",
            "Pseudolithos",
            "Rabiea",
            "Rhombophyllum",
            "Rosularia",
            "Sansevieria",
            "Sedeveria",
            "Sedum",
            "Sempervivum",
            "Senecio",
            "Sinocrassula",
            "Stapelia",
            "Stapelianthus",
            "Stapeliopsis",
            "Tavaresia",
            "Titanopsis",
            "Trachyandra",
            "Tradescantia",
            "Trichodiadema",
            "Tromotriche",
            "Tylecodon",
            "Umbilicus",
            "Xerosicyos"
        */
        succulents: [
            "Alpine balm",
            "Alpine bells",
            "Alpine gentian",
            "Balloon flower",
            "Blue fescue",
            "Bouquet-Narcissus",
            "Bronze knotweed",
            "Candytuft",
            "Carpet phlox",
            "Common gorse",
            "Common Pasque Flower",
            "Common Siegwurz",
            "Cyclamen Daffodil",
            "Dwarf gorse",
            "Edelweiss",
            "Fan dwarf mistletoe",
            "Fescue",
            "Fringed carnation",
            "Garden blue pillows",
            "Gold Leek",
            "Grassy Tufted Bell",
            "Gray cranesbill",
            "Grape hyacinth",
            "Great sedum",
            "Greek fescue",
            "Houseleek",
            "Ice flower",
            "Lilac Primrose",
            "Little Evergreen",
            "Lovage",
            "Ordinary cat paw",
            "Pyrenees rock plate",
            "Red wonder clover",
            "Rock stone herb",
            "Roller Spurge",
            "Sedum plant",
            "Summer Adonis",
            "Summer Azalea",
            "Summer Carnations",
            "Stalkless catchfly",
            "Star ball leek",
            "Stone carnations",
            "Storchenschnabel",
            "Vinca minor",
            "Vineyard tulip",
            "Water lily tulip",
        ],

    },
    de: {
        /*"Adenium",
            "Adromischus",
            "Aeonium",
            "Agave",
            "Albuca",
            "Aloe",
            "Aloinopsis",
            "Alworthia",
            "Anacampseros",
            "Antegibbaeum",
            "Antimima",
            "Aptenia",
            "Argyroderma",
            "Astridia",
            "Astroloba",
            "Astroworthia",
            "Avonia",
            "Bergeranthus",
            "Bijlia",
            "Braunsia",
            "Callisia",
            "Carruanthus",
            "Cephalophyllum",
            "Ceropegia",
            "Cheiridopsis",
            "Conophytum",
            "Corpuscularia",
            "Cotyledon",
            "Crassula",
            "Cremnosedum",
            "Dinteranthus",
            "Dudleya",
            "Dyckia",
            "Ebracteola",
            "Echeveria",
            "Echidnopsis",
            "Euphorbia",
            "Faucaria",
            "Fenestaria",
            "Frithia",
            "Gasteraloe",
            "Gasterhaworthia",
            "Gasteria",
            "Gibbaeum",
            "Glottiphyllum",
            "Graptopetalum",
            "Graptosedum",
            "Graptoveria",
            "Haworthia",
            "Huernia",
            "Ihlenfeldtia",
            "Jensenobotrya",
            "Juttadinteria",
            "Kalanchoe",
            "Lapidaria",
            "Larryleachia",
            "Lenophyllum",
            "Lithops",
            "Mangave",
            "Monanthes",
            "Neohenricia",
            "Odontophorus",
            "Orbea",
            "Orostachys",
            "Oscularia",
            "Othonna",
            "Pachyphytum",
            "Pachyveria",
            "Peperomia",
            "Pleiospilos",
            "Portulaca",
            "Portulacaria",
            "Prenia",
            "Pseudolithos",
            "Rabiea",
            "Rhombophyllum",
            "Rosularia",
            "Sansevieria",
            "Sedeveria",
            "Sedum",
            "Sempervivum",
            "Senecio",
            "Sinocrassula",
            "Stapelia",
            "Stapelianthus",
            "Stapeliopsis",
            "Tavaresia",
            "Titanopsis",
            "Trachyandra",
            "Tradescantia",
            "Trichodiadema",
            "Tromotriche",
            "Tylecodon",
            "Umbilicus",
            "Xerosicyos"*/
        succulents: [
            "Alpenbalsam",
            "Alpenenzian",
            "Alpenglöckchen",
            "Alpenveilchen-Narzisse",
            "Ballonblume",
            "Blauschwingel",
            "Bronzeknöterich",
            "Edelweiß",
            "Fächer-Zwergmistel",
            "Felsen-Steinkraut",
            "Fetthenne",
            "Fliederprimel",
            "Fransennelke",
            "Garten-Blaukissen",
            "Gewöhnliche Küchenschelle",
            "Gewöhnliche Siegwurz",
            "Gewöhnlicher Flügelginster",
            "Gewöhnliches Katzenpfötchen",
            "Gold-Lauch",
            "Grasartige Büschelglocke",
            "Grauer Storchschnabel",
            "Griechischer Stachelschwingel",
            "Große Fetthenne",
            "Hauswurz",
            "Kleines Immergrün",
            "Liebstöckel",
            "Mittagsblume",
            "Pyrenäen-Felsenteller",
            "Roter Wunderklee",
            "Schleifenblume",
            "Seerosen-Tulpe",
            "Sommerazalee",
            "Sommer-Adonisröschen",
            "Sommernelken",
            "Stachelschwingel",
            "Stängelloses Leimkraut",
            "Steinnelken",
            "Sternkugel-Lauch",
            "Storchenschnabel",
            "Strauß-Narzisse",
            "Teppichphlox",
            "Traubenhyazinthe",
            "Vinca minor",
            "Walzen-Wolfsmilch",
            "Weinbergtulpe",
            "Zwergginster",
        ],
    }
}