export default {
    en: {
        /*  "Brautspiere",
            "Sternmagnolie",
            "Goldglöckchen",
            "Rhododendorn",
            "Rosen",
            "Schmetterlingsflieder",
            "Weigelie",
            "Pfeifenstrauch",
            "Rispenhortensie",
            "Sternchenstrauch",
            "Herbst-Flieder",
            "Ball-Hortensie",
            "Sieben-Söhne-des-Himmels-Strauch",
            "Mönchspfeffer",
            "Waldrebe",
            "Winterjasmin",
            "Winterschneeball",
            "Zaubernuss",
            "Winterheide",
            "Lorbeer-Schneeball",
            //weitere
            "Brautspiere",
            "Forsythie",
            "Goldregen",
            "Rhododendorn",
            "Sternmagnolie",
            "Weißdorn",
            "Duftjasmin",
            "Färberginster",
            "Schmetterlingsstrauch",
            "Schönfrucht",
            "Franklinie",
            "Herbst-Flieder",
            "Mönchspfeffer",
            "Chinesische Winterblüte",
            "Winterschneeball",
            "Zaubernuss",
            //14 schnellwachsende Büsche
            "Bauernjasmin",
            "Perlmuttstrauch",
            "Echter Schneeball",
            "Sommerflieder",
            "Garteneibisch",
            "Strauchkastanie",
            "Bartblume",
            "Streifenstrauch",
            "Gewürzstrauch",
            "Spanischer Ginster",
            "Japanische Zierquitte",
            "Schwarzer Holunder",
            "Gemeine Berberitze",
            "Purpurhasel",
        */
        bushes: [
            "Asterisk shrub",
            "Autumn Lilac",
            "Ball hydrangea",
            "Beard Flower",
            "Black elder",
            "Bridal spears",
            "Buddleia",
            "Bush Chestnut",
            "Butterfly Bush",
            "Butterfly lilac",
            "Chinese Winter Blossom",
            "Clematis",
            "Common barberry",
            "Dyer's gorse",
            "Farmer's Jasmine",
            "Forsythia",
            "Frank line",
            "Garden hawk",
            "Gold bells",
            "Hawthorn",
            "Japanese Quince",
            "Laburnum",
            "Laurel Snowball",
            "Monk's pepper",
            "Mother-of-pearl",
            "Nice fruit",
            "Panicle hydrangea",
            "Pipe bush",
            "Purple hazel",
            "Real snowball",
            "Rhododendorn",
            "Roses",
            "Scented Jasmine",
            "Seven Sons of Heaven",
            "Spanish gorse",
            "Spice Shrub",
            "Star Magnolia",
            "Striped Shrub",
            "Weigela",
            "Winter Heath",
            "Winter Jasmine",
            "Winter Snowball",
            "Witch Hazel",
        ]
    },
    de: {
        bushes: [
            "Ball-Hortensie",
            "Bartblume",
            "Bauernjasmin",
            "Brautspiere",
            "Chinesische Winterblüte",
            "Duftjasmin",
            "Echter Schneeball",
            "Färberginster",
            "Forsythie",
            "Franklinie",
            "Garteneibisch",
            "Gemeine Berberitze",
            "Gewürzstrauch",
            "Goldglöckchen",
            "Goldregen",
            "Herbst-Flieder",
            "Japanische Zierquitte",
            "Lorbeer-Schneeball",
            "Mönchspfeffer",
            "Perlmuttstrauch",
            "Pfeifenstrauch",
            "Purpurhasel",
            "Rhododendorn",
            "Rispenhortensie",
            "Rosen",
            "Schmetterlingsflieder",
            "Schmetterlingsstrauch",
            "Schönfrucht",
            "Schwarzer Holunder",
            "Sieben-Söhne-des-Himmels-Strauch",
            "Sommerflieder",
            "Spanischer Ginster",
            "Sternchenstrauch",
            "Sternmagnolie",
            "Strauchkastanie",
            "Streifenstrauch",
            "Waldrebe",
            "Weigelie",
            "Weißdorn",
            "Winterheide",
            "Winterjasmin",
            "Winterschneeball",
            "Zaubernuss",
        ],
        
    }
}